import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import _ from "lodash";
import Helmet from "react-helmet";
import favicon from "../assets/img/yolk.ico";
import "../layout/index.sass"
import "../style/main.sass"

class YolkPage extends Component {
  render() {
    const yolk1 = this.props.data.yolk1.childImageSharp.gatsbyImageData;
    const yolk2 = this.props.data.yolk2.childImageSharp.gatsbyImageData;
    const eeto = this.props.data.eeto.frontmatter.content;
    return (
      <div className="yolk-bg" style={{minHeight: "100vh"}}>
        <Helmet>
          <title>yolkgirl</title>
          <link rel="icon" href={favicon} />
          <meta name="title" content="yolkgirl" />
          <meta name="description" content="yolk" />
          <html lang="en" />
        </Helmet>
        <nav
          className="navbar horizontal-container"
          role="navigation"
          aria-label="main navigation"
          style={{backgroundColor: "transparent"}}
        >
          <div className="navbar-brand" >
            <Link style={{backgroundColor: "transparent"}} className="navbar-item navbar-brand-left" to="/">
              <GatsbyImage
                className="logo-icon"
                image={this.props.data.yolk.childImageSharp.gatsbyImageData}
              />
              <span className="navbar-brand-text has-text-yolk">yolkgirl</span>
            </Link>
          </div>

          <hr className="navbar-divider" />
        </nav>
        <div className="layout-container content" style={{backgroundColor: "transparent"}}>
          <section className="hero">
            <div className="hero-body">
              <div className="container">
                <div className="columns">
                  <div className="column" style={{ wordBreak: "break-word" }}>
                    <h1 className="title is-size-2">
                      welcome to <span className="has-text-yolk">yolk</span>
                    </h1>

                    <div className="is-size-5">
                      <GatsbyImage image={yolk1} />
                      <img class="emote" src="/img/emotes/cool.gif"></img>
                      <img class="emote" src="/img/emotes/cool.gif"></img>
                      <img class="emote" src="/img/emotes/cool.gif"></img>
                      <img class="emote" src="/img/emotes/cool.gif"></img>
                      <img class="emote" src="/img/emotes/cool.gif"></img>
                    </div>
                  </div>
                  <div className="column">
                    <GatsbyImage image={yolk2} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section">
            {/* <h3>friendnet</h3> */}
            <p class="eeto" style={{backgroundColor: "transparent"}}>
              {eeto.map((f) => (
                <a href={f.site} style={{backgroundColor: "transparent"}}>
                  <img src={"/img/8831/" + f.eeto}></img>
                </a>
              ))}
            </p>
          </section>
          <a href="/" style={{backgroundColor: "transparent"}} >return to sanity</a>
        </div>
      </div>
    );
  }
}

export default YolkPage;

export const pageQuery = graphql`
  query YolkQuery {
    yolk: file(name: { eq: "theyolk" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 24, height: 24)
      }
    }
    yolk1: file(name: { eq: "2024-yolk-girl-1" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 300)
      }
    }
    yolk2: file(name: { eq: "2024-yolk-girl-2" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 300)
      }
    }
    eeto: markdownRemark(frontmatter: { for: { eq: "eeto" } }) {
      frontmatter {
        content {
          eeto
          site
        }
      }
    }
  }
`;
